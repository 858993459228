/**
 *
 * @param hotel
 * @returns {*}
 */
const convertHotelNameToCode = hotel => {
  return hotel.normalize("NFD").replace(/\p{Diacritic}|\s|&/gu, "")
}

/**
 *
 * @param rooms
 * @param data
 * @returns {*}
 */
const searchRooms = (rooms, data) => {
  let searchResults = rooms

  // Filter by hotel
  if (data.hotel && data.hotel !== "all") {
    searchResults = searchResults.filter(item =>
      convertHotelNameToCode(item.relationships.room_hotel.name).includes(
        data.hotel
      )
    )
  }

  // Filter by search text
  if (data.query) {
    searchResults = searchResults.filter(item =>
      item.title.toLowerCase().includes(data.query.toLowerCase())
    )
  }

  return searchResults
}

/**
 *
 * @param awards
 * @param data
 * @returns {*}
 */
 const searchAwards = (awards, data) => {
  let searchResults = awards
  
  // Filter by hotel
  if (data.category && data.category !== "all") {
    searchResults = searchResults.filter(item =>
      item.relationships.award_categories.some(
        category => category.id === data.category
      )
    )
  }
  
  // Filter by year
  if (data.year) {
    searchResults = searchResults.filter(item => item.year === data.year)

  }

  // Filter by search text
  // if (data.query) {
  //   searchResults = searchResults.filter(item =>
  //     item.title.toLowerCase().includes(data.query.toLowerCase())
  //   )
  // }

  return searchResults
}

/**
 *
 * @param pressItems
 * @param data
 * @returns {*}
 */
const searchPress = (pressItems, data) => {
  let searchResults = pressItems

  // Filter by category
  if (data.selectedCategory) {
    searchResults = searchResults.filter(item =>
      item.relationships.press_release_categories.some(
        category => category.id === data.selectedCategory
      )
    )
  }

  // Filter by tag
  if (data.selectedTag) {
    searchResults = searchResults.filter(item =>
      item.relationships.press_release_tags.some(
        tag => tag.id === data.selectedTag
      )
    )
  }

  // Filter by year
  if (data.selectedYear) {
    searchResults = searchResults.filter(
      item => item.year === data.selectedYear
    )
  }

  // Filter by search text
  if (data.query) {
    searchResults = searchResults.filter(item =>
      item.title.toLowerCase().includes(data.query.toLowerCase())
    )
  }

  return searchResults.filter(item => item.title !== "Dummy") // Ignore Dummy content
}
const searchExperience = (experiences, data) => {
  let searchResults = experiences


  // Filter by hotel
  if (data.hotel) {
    // console.log(searchResults)
    searchResults = searchResults.filter(item =>
      (item.relationships?.experience_article_hotel !== null && item.relationships?.experience_article_hotel.id === data.hotel)
    )
  }

  // Filter by genre
  if (data.genre) {
    searchResults = searchResults.filter(item =>
      (item.relationships.genre !== null && item.relationships.genre.id === data.genre)
    )
  }

  // // Filter by year
  // if (data.selectedYear) {
  //   searchResults = searchResults.filter(
  //     item => item.year === data.selectedYear
  //   )
  // }

  // Filter by search text
  if (data.query) {
    searchResults = searchResults.filter(item =>
      item.title.toLowerCase().includes(data.query.toLowerCase()) || item.relationships.experience.name.toLowerCase().includes(data.query.toLowerCase()) || item.relationships?.experience_article_hotel.name.toLowerCase().includes(data.query.toLowerCase()) || item.relationships.genre.name.toLowerCase().includes(data.query.toLowerCase())
    )
  }

  return shuffleArray(searchResults).filter(item => item.title !== "Dummy") // Ignore Dummy content
}
function shuffleArray(array){
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}
export { convertHotelNameToCode, searchRooms, searchAwards, searchPress, searchExperience }
