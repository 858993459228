/**
 *
 * @param hotel
 * @returns {*}
 */
const convertHotelNameToCode = hotel => {
  return hotel.normalize("NFD").replace(/\p{Diacritic}|\s|&/gu, "")
}

const convertNameToCode = name => {
  return name.normalize("NFD").replace(/\p{Diacritic}|\s|&/gu, "")
}

const getHotelIDFr = hotel => {
  switch (hotel) {
    case "VerandaPaulEtVirginie":
      return "ecdc96c7-17c0-5ff6-bb44-078d66e9534d"
    case "VerandaPointeauxBiches":
      return "c03caab4-abb8-5ea2-8364-7f00965caf4c"
    case "VerandaGrandBaie":
      return "f8da05e7-8397-539f-a965-2069f7de004b"
    case "VerandaTamarin":
      return "f8da05e7-8397-539f-a965-2069f7de004b"
    case "VerandaPalmarBeach":
      return "b17ebc59-7d4a-546c-84ad-e1b30e51fcc3"
    default:
      return ""
  }
}

const getHotelIDEn = hotel => {
  switch (hotel) {
    case "VerandaPaulEtVirginie":
      return "6a93511e-cda7-5b79-adc3-80734f6e6cef"
    case "VerandaPointeauxBiches":
      return "dc3e7f55-8eaf-541e-b441-bf0f91e04455"
    case "VerandaGrandBaie":
      return "f422a8a1-874f-5810-b76f-46088d704ba3"
    case "VerandaTamarin":
      return "4870c97d-8dcc-58fd-a9bf-dfd17b2b686c"
    case "VerandaPalmarBeach":
      return "2d780cf5-c9e9-5a59-b4b7-c623cb411d72"
    default:
      return ""
  }
}
/**
 *
 * @param offers
 * @param data
 * @returns {*}
 */
const searchOffers = (offers, data) => {
  let searchResults = offers

  // Filter by hotel
  if (data.hotel && data.hotel !== "all") {
    searchResults = searchResults.filter(item =>
      convertHotelNameToCode(item.relationships.offer_hotel.name).includes(
        data.hotel
      )
    )
  }

  // Filter by search text
  if (data.query) {
    searchResults = searchResults.filter(item =>
      item.title.toLowerCase().includes(data.query.toLowerCase())
    )
  }

  return searchResults
}

export {
  convertHotelNameToCode,
  searchOffers,
  convertNameToCode,
  getHotelIDEn,
  getHotelIDFr,
}
