import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import {
  HOTEL_TAXONOMY_HERITAGE_AWALI_ID,
  HOTEL_TAXONOMY_HERITAGE_LE_TELFAIR_ID,
  HOTEL_TAXONOMY_HERITAGE_RESORTS_ID,
  HOTEL_TAXONOMY_HERITAGE_VILLAS_ID,
} from "../../../constants"
import { generateUrlByLocale } from "../../../services/intl.service"
import Media from "../../common/media"
import Title from "../../common/title"
import { useIntl } from "gatsby-plugin-react-intl"
import parse from "html-react-parser"
import StyledButton from "../../common/styledButton"
import { useForm } from "react-hook-form"
import {
  FormControl,
  Input,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material"
import {
  convertHotelNameToCode,
  convertNameToCode,
  searchOffers,
  getHotelIDEn,
  getHotelIDFr,
} from "../../../services/listingoffer.service"
import queryString from "query-string"
import { Link } from "gatsby"
import { debounce, trimSentence } from "../../functions"
import { searchExperience } from "../../../services/listing.service"
import { element } from "prop-types"

const BlockListingExperiences = ({ experiences, hotelTaxonomyId, genres }) => {
  const intl = useIntl()

  const breakpoint = 640

  const [isClient, setClient] = useState(false)

  let items = experiences

  const isSSR = typeof window === "undefined"
  const [results, setResults] = useState([])
  const [selectedHotel, setSelectedHotel] = useState("")
  const [selectedGenre, setSelectedGenre] = useState("")
  const [searchQuery, setSearchQuery] = useState("")
  const experiencePerRow = 9

  // Get all hotels from rooms
  const hotels = items.map(hotel => {
    return hotel.relationships?.experience_article_hotel
  })

  // Remove duplicate hotels
  const uniqueHotels = Array.from(new Set(hotels.map(h => h.id))).map(id => {
    return hotels.find(h => h.id === id)
  })

  // Handle hotel radio change
  const inputRef = React.useRef(null)
  const handleHotelChange = event => {
    setSelectedHotel(event.target.value)
    setNext(experiencePerRow)
    //Set click on submit after radio change
    setTimeout(() => {
      inputRef.current.click()
    }, 500)
  }

  const { register, handleSubmit } = useForm()
  const onSubmit = function (data, e) {
    e.preventDefault()
    const payload = {
      hotel: selectedHotel,
      genre: selectedGenre,
      query: searchQuery,
    }

    setResults(searchExperience(items, payload))
  }

  // Handle tag dropdown change
  const handleGenreChange = event => {
    setSelectedGenre(event.target.value)
    setNext(experiencePerRow)
    //Set click on submit after radio change
    setTimeout(() => {
      inputRef.current.click()
    }, 500)
  }
  const handleQueryChange = event => {
    setSearchQuery(event.target.value)
    setNext(experiencePerRow)
    //Set click on submit after radio change
    setTimeout(() => {
      inputRef.current.click()
    }, 300)
  }

  const shuffleArray = array => {
    const newArray = [...array]
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[newArray[i], newArray[j]] = [newArray[j], newArray[i]]
    }
    return newArray
  }

  let parsed, hotelParam, genreParam

  if (!isSSR) {
    parsed = queryString.parse(window.location.search)
    hotelParam = parsed?.hotel ? convertNameToCode(parsed.hotel) : ""
    genreParam = parsed?.genre ? convertNameToCode(parsed.genre) : ""
  }

  const genreFromUrl = genres.filter(
    genre => convertNameToCode(genre.name) === genreParam
  )

  const hotelFromUrlFR = getHotelIDFr(hotelParam)
  const hotelFromUrlEN = getHotelIDEn(hotelParam)
  let hotelID
  switch (intl.locale) {
    case "fr":
      hotelID = hotelFromUrlFR
      break
    default:
      hotelID = hotelFromUrlEN
  }

  const [next, setNext] = useState(experiencePerRow)
  const handleMoreExperiences = () => {
    setTimeout(() => {
      document.body.classList.add("scroll-down")
    }, 500)
    setNext(next + experiencePerRow)
  }
  const showInput = () => {
    var input = document.getElementById("query")
    input.style.display = "block"
    input.focus()
  }

  useEffect(() => {
    setResults(experiences)
    if (genreParam) {
      setSelectedGenre(genreFromUrl[0]?.id)
      setResults(searchExperience(items, { genre: genreFromUrl[0]?.id }))
    } else {
      setSelectedGenre(genreFromUrl[0]?.id)
      setResults(searchExperience(items, { genre: genreFromUrl[0]?.id }))
    }

    if (hotelParam) {
      setSelectedHotel(hotelID)
      setResults(searchExperience(items, {hotel: hotelID}))
    }

    setClient(true)

    setNext(experiencePerRow)
  }, [])
  let count = 0
  results.forEach(element => {
    count += 1
  })
  return (
    <>
      <section id="filterExp" className="filterBar">
        <form className="formPressRelease" onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <Row>
              {genres && genres.length > 0 && (
                <Col md={2} xs={12}>
                  <select onChange={handleGenreChange} value={selectedGenre}>
                    <option value="">
                      {intl.formatMessage({
                        id: "form_inputs.themes",
                      })}
                    </option>
                    {genres
                      .filter(genres => genres)
                      .map((genre, index) => {
                        return (
                          <option key={index} value={genre.id}>
                            {genre.name}
                          </option>
                        )
                      })}
                  </select>
                </Col>
              )}
              <Col md={2} xs={12} className="expDummy"></Col>
              {/* {uniqueHotels && uniqueHotels.length > 0 && (
                <Col md={2} xs={12} className="hotelFilter">
                  <select
                    id="selectHotel"
                    onChange={handleHotelChange}
                    value={selectedHotel}
                  >
                    <option value="">
                      {intl.formatMessage({
                        id: "form_inputs.all_experiences",
                      })}
                    </option>
                    {uniqueHotels
                      .filter(
                        hotel =>
                          hotel
                      )
                      .map((hotel, index) => {
                        return (
                          <option key={index} value={hotel.id}>
                            {hotel.name}
                          </option>
                        )
                      })}
                  </select>
                </Col>
              )} */}
              <Col className="search-press" md={8} xs={12}>
                <div className="searchMob">
                  <div className="search-box searchInp ">
                    <button
                      ref={inputRef}
                      className="btn-search btnSubmitSearch"
                      type="submit"
                    >
                      <i className="icon-search"></i>
                    </button>
                    <input
                      id="query"
                      type="text"
                      className="input-search"
                      placeholder={intl.formatMessage({
                        id: "page.search.search_placeholder",
                      })}
                      onChange={handleQueryChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </form>
      </section>
      <section id="blkExperienceListing" className="section-listing">
        <Container>
          {results.length > 0 && (
            <Row className="num_results">
              <span className="info-detail">
                {count} {intl.formatMessage({ id: "page.search.results" })}
              </span>
            </Row>
          )}
          <Row className="listing-generic press">
            {results.length > 0 ? (
              results?.slice(0, next)?.map((experience, index) => {
                return (
                  <>
                    <div key={index}>
                      <a
                        href={generateUrlByLocale(
                          experience.path.langcode,
                          experience.path.alias
                        )}
                        onClick={event => {
                          event.preventDefault()
                          window.location.href = generateUrlByLocale(
                            experience.path.langcode,
                            experience.path.alias
                          )
                        }}
                      >
                        {/* {experience.relationships?.experience_article_hotel && (
                            <span className="caption">
                            <span>
                              {
                                experience.relationships?.experience_article_hotel.name
                              }
                            </span>
                          </span>
                          )
                        } */}
                        <Media
                          media={
                            experience.relationships
                              .experience_article_featured_land.relationships
                              .featured_media_media
                          }
                        />
                        <div className="caption">
                          {experience.relationships.genre && (
                            <span>{experience.relationships.genre.name}</span>
                          )}
                        </div>
                        {experience.title && (
                          <Title heading={2} text={experience.title} />
                        )}
                      </a>

                      {experience.path && (
                        <a
                          className="discoverBtn simpleLink"
                          href={generateUrlByLocale(
                            experience.path.langcode,
                            experience.path.alias
                          )}
                          onClick={event => {
                            event.preventDefault()
                            window.location.href = generateUrlByLocale(
                              experience.path.langcode,
                              experience.path.alias
                            )
                          }}
                        >
                          <span>{intl.formatMessage({ id: "discover" })}</span>
                        </a>
                      )}

                      {/*<StyledButton*/}
                      {/*  type="link"*/}
                      {/*  path={generateUrlByLocale(*/}
                      {/*    experience.path.langcode,*/}
                      {/*    experience.path.alias*/}
                      {/*  )}*/}
                      {/*  title={intl.formatMessage({ id: "discover" })}*/}
                      {/*/>*/}
                    </div>
                  </>
                )
              })
            ) : (
              <div className="no-results">
                <h2>{intl.formatMessage({ id: "page.search.no_results" })}</h2>
              </div>
            )}
          </Row>
        </Container>
      </section>
      {next <= results?.length && (
        <section className="section-more blk-spacing">
          <Container>
            <Row>
              <div className="grid-load-more">
                <button className="btn" onClick={handleMoreExperiences}>
                  <span>{intl.formatMessage({ id: "show_more" })}</span>
                </button>
              </div>
            </Row>
          </Container>
        </section>
      )}
    </>
  )
}

export default BlockListingExperiences
