import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import parse from "html-react-parser"
import { useIntl } from "gatsby-plugin-react-intl"
import ImageSlider from "../../Room/imageSlider"
import Title from "../../common/title"
import { generateUrlByLocale } from "../../../services/intl.service"
import StyledButton from "../../common/styledButton"
import { HOTEL_TAXONOMY_VERANDA_GRAND_BAIE_ID, HOTEL_TAXONOMY_VERANDA_PALMAR_BEACH_ID, HOTEL_TAXONOMY_VERANDA_PAUL_VIRGINIE_ID, HOTEL_TAXONOMY_VERANDA_POINTE_AUX_BICHES_ID, HOTEL_TAXONOMY_VERANDA_TAMARIN_ID } from "../../../constants"
import {Link} from "gatsby";

const BlockListingRooms = ({ rooms, hotelTaxonomyId }) => {
    const intl = useIntl()

    let paramHotelName
    let items = rooms

    items = items.filter(a => {
        switch (hotelTaxonomyId) {
            case HOTEL_TAXONOMY_VERANDA_POINTE_AUX_BICHES_ID:
                paramHotelName = "VerandaPointeAuBiches"
                return a.relationships.room_hotel.drupal_id === HOTEL_TAXONOMY_VERANDA_POINTE_AUX_BICHES_ID
            case HOTEL_TAXONOMY_VERANDA_TAMARIN_ID:
                paramHotelName = "VerandaTamarin"
                return a.relationships.room_hotel.drupal_id === HOTEL_TAXONOMY_VERANDA_TAMARIN_ID
            case HOTEL_TAXONOMY_VERANDA_PALMAR_BEACH_ID:
                paramHotelName = "VerandaPalmarBeach"
                return a.relationships.room_hotel.drupal_id === HOTEL_TAXONOMY_VERANDA_PALMAR_BEACH_ID
            case HOTEL_TAXONOMY_VERANDA_GRAND_BAIE_ID:
                paramHotelName = "VerandaGrandBaie"
                return a.relationships.room_hotel.drupal_id === HOTEL_TAXONOMY_VERANDA_GRAND_BAIE_ID
            case HOTEL_TAXONOMY_VERANDA_PAUL_VIRGINIE_ID:
                paramHotelName = "VerandaPaulEtVirginie"
                return a.relationships.room_hotel.drupal_id === HOTEL_TAXONOMY_VERANDA_PAUL_VIRGINIE_ID
            default:
                return a
        }
    })

    const listItems = items.map((item, index) => (
        <Col md={12} xs={12} key={index}>
            <div className="listingDetails">
                <Container>
                    <div>
                        {item.relationships.room_carousel.relationships
                            .carousel_highlight_item[0].relationships.media && (
                                <ImageSlider
                                    images={
                                        item.relationships.room_carousel.relationships
                                            .carousel_highlight_item
                                    }
                                />
                            )}
                    </div>
                    <div className="listingInfo">
                        <Title heading={2} size={2} text={item.title} />
                        <div className="description alignRight">
                            {" "}
                            {parse(item.room_description.processed)}
                        </div>
                        <div className="description">
                            {" "}
                            {intl.formatMessage({
                                id: "page.room.as_from",
                            })}{" "}
                            <br />
                            <span className="price-listing">
                                <span className="priceStyle">
                                    <em>{item.room_price}</em> <em>{item.room_price_suffix}</em>
                                </span>
                            </span>
                            <br />{" "}
                            {intl.formatMessage({
                                id: "page.room.par_nuit_par_room",
                            })}
                        </div>
                        <br />
                        {item?.room_reservation_link && (
                          <Link
                            to={item?.link_de?.url}
                            target="_blank"
                            className="btn btnPrimary germanLink"
                          >
                              <span>{item?.room_reservation_link?.title}</span>
                          </Link>
                        )}
                        {item.room_reservation_link && (
                          <Link
                            to={item.room_reservation_link.url}
                            target="_blank"
                            className="btn btnPrimary englishLink"
                          >
                              <span>{item.room_reservation_link.title}</span>
                          </Link>
                        )}
                        <Row className="pt-25">
                            <Col md={6} xs={6}>
                                <StyledButton
                                    title={intl.formatMessage({ id: "learn_more" })}
                                    type="link"
                                    path={generateUrlByLocale(item.path.langcode, item.path.alias)}
                                />
                            </Col>
                            <Col md={6} xs={6}>
                                 <StyledButton
                                    title={intl.formatMessage({ id: "form_inputs.ask_quote" })}
                                    type="link"
                                    path={
                                        intl.formatMessage({ id: "menu.ask_a_quote.link" }) +
                                        "/?hotel=" +
                                        paramHotelName
                                    }
                                />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </Col>
    ))

    return (
        <section id="listing">
            <Container fluid>
                <Row className="rowListing">{listItems}</Row>
            </Container>
        </section>
    )
}

export default BlockListingRooms
