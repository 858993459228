/**
 *
 * @param hotel
 * @returns {*}
 */
const convertHotelNameToCode = hotel => {
  return hotel.normalize("NFD").replace(/\p{Diacritic}|\s|&/gu, "")
}

/**
 *
 * @param offers
 * @param data
 * @returns {*}
 */
const searchEvents = (events, data) => {
  let searchResults = events

  // Filter by hotel
  if (data.hotel && data.hotel !== "all") {
    searchResults = searchResults.filter(item =>
      convertHotelNameToCode(item.relationships.event_hotel.name).includes(
        data.hotel
      )
    )
  }

  // Filter by search text
  if (data.query) {
    searchResults = searchResults.filter(item =>
      item.title.toLowerCase().includes(data.query.toLowerCase())
    )
  }

  return searchResults
}

export { convertHotelNameToCode, searchEvents }
