import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import parse from "html-react-parser"
import { useIntl } from "gatsby-plugin-react-intl"
import Title from "../../common/title"
import { generateUrlByLocale } from "../../../services/intl.service"
import StyledButton from "../../common/styledButton"
import { HOTEL_TAXONOMY_VERANDA_GRAND_BAIE_ID, HOTEL_TAXONOMY_VERANDA_PALMAR_BEACH_ID, HOTEL_TAXONOMY_VERANDA_PAUL_VIRGINIE_ID, HOTEL_TAXONOMY_VERANDA_POINTE_AUX_BICHES_ID, HOTEL_TAXONOMY_VERANDA_TAMARIN_ID, HOTEL_TAXONOMY_VERANDA_RESORTS_ID } from "../../../constants"
import Media from "../../common/media"
import { trimSentence } from "../../functions"
import {useForm} from "react-hook-form";
import {convertHotelNameToCode, searchOffers} from "../../../services/listingoffer.service";
import {
    FormControl,
    Input,
    InputLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material"
import queryString from "query-string"

const BlockListingOffers = ({ offers, hotelTaxonomyId }) => {
    const intl = useIntl()
    const isSSR = typeof window === "undefined"
    let items = offers
    const [results, setResults] = useState([])
    const [hotel, setHotel] = useState(null)

    items = results.filter(a => {
        switch (hotelTaxonomyId) {
            case HOTEL_TAXONOMY_VERANDA_POINTE_AUX_BICHES_ID:
                return a.relationships.offer_hotel.drupal_id === HOTEL_TAXONOMY_VERANDA_POINTE_AUX_BICHES_ID
            case HOTEL_TAXONOMY_VERANDA_TAMARIN_ID:
                return a.relationships.offer_hotel.drupal_id === HOTEL_TAXONOMY_VERANDA_TAMARIN_ID
            case HOTEL_TAXONOMY_VERANDA_PALMAR_BEACH_ID:
                return a.relationships.offer_hotel.drupal_id === HOTEL_TAXONOMY_VERANDA_PALMAR_BEACH_ID
            case HOTEL_TAXONOMY_VERANDA_GRAND_BAIE_ID:
                return a.relationships.offer_hotel.drupal_id === HOTEL_TAXONOMY_VERANDA_GRAND_BAIE_ID
            case HOTEL_TAXONOMY_VERANDA_PAUL_VIRGINIE_ID:
                return a.relationships.offer_hotel.drupal_id === HOTEL_TAXONOMY_VERANDA_PAUL_VIRGINIE_ID
            default:
                return a
        }
    })

    const getParamHotelName = (offerHotelId) => {
        switch (offerHotelId) {
            case HOTEL_TAXONOMY_VERANDA_POINTE_AUX_BICHES_ID:
                return "VerandaPointeAuBiches"
            case HOTEL_TAXONOMY_VERANDA_TAMARIN_ID:
                return "VerandaTamarin"
            case HOTEL_TAXONOMY_VERANDA_PALMAR_BEACH_ID:
                return "VerandaPalmarBeach"
            case HOTEL_TAXONOMY_VERANDA_GRAND_BAIE_ID:
                return "VerandaGrandBaie"
            case HOTEL_TAXONOMY_VERANDA_PAUL_VIRGINIE_ID:
                return "VerandaPaulEtVirginie"
            default:
                return ""
        }
    }

    // Get all hotels from rooms
    const hotels = offers.map(hotel => {
        return hotel.relationships.offer_hotel
    })

    // Remove duplicate hotels
    const uniqueHotels = Array.from(new Set(hotels.map(h => h.id))).map(id => {
        return hotels.find(h => h.id === id)
    })

    // Handle hotel radio change
    const inputRef = React.useRef(null)
    const handleHotelChange = event => {
        setHotel(event.target.value)

        //Set click on submit after radio change
        setTimeout(() => {
            inputRef.current.click()
        }, 500)
    }

    const { register, handleSubmit } = useForm()
    const onSubmit = function (data) {
        const payload = {
            hotel: hotel,
            query: data.query,
        }

        setResults(searchOffers(offers, payload))
    }

    let parsed, hotelParam

    if (!isSSR) {
        parsed = queryString.parse(window.location.search)
        hotelParam = parsed?.hotel ? convertHotelNameToCode(parsed.hotel) : ""
    }

    useEffect(() => {
        if (hotelParam) {
            setHotel(hotelParam)
            setResults(searchOffers(offers, { hotel: hotelParam }))
        } else {
            setHotel("all")
            setResults(offers)
        }
    }, [])

    const listItems = items.map((item, index) => (
        <Col md={12} xs={12} key={index}>
            <div className="listingDetails">
                <Container>
                    <div>
                        <Media
                            media={
                                item.relationships.offer_featured_media_landscape
                                    .relationships.featured_media_media
                            }
                        />
                    </div>
                    <div className="listingInfo">
                        <Title heading={2} size={2} text={item.title} />
                        <div className="description alignRight">
                            {" "}
                            {parse(
                                trimSentence(item.offer_featured_description.processed, 200)
                            )
                            }
                        </div>
                        <br />
                        <Row className="pt-25">
                            <Col md={6} xs={6}>
                                <StyledButton
                                    title={intl.formatMessage({ id: "learn_more" })}
                                    type="link"
                                    path={generateUrlByLocale(item.path.langcode, item.path.alias)}
                                />
                            </Col>
                            <Col md={6} xs={6}>
                                {item.relationships.offer_introduction &&
                                  <div className="linkOther">
                                      <StyledButton
                                        path={item.relationships.offer_introduction.introduction_link?.url}
                                        title={item.relationships.offer_introduction.introduction_link?.title}
                                        type="link"
                                      />
                                  </div>
                                }
                                {item.relationships.offer_introduction.link_de &&
                                  <div className="linkDE">
                                      <StyledButton
                                        path={item.relationships.offer_introduction.link_de?.url}
                                        title={item.relationships.offer_introduction.introduction_link?.title}
                                        type="link"
                                      />
                                  </div>
                                }
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </Col>
    ))

    return (
        <section id="listing">
            {hotelTaxonomyId === HOTEL_TAXONOMY_VERANDA_RESORTS_ID && (
              <Container className="filterList">
                  <form className="filterForm" onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                          <Col md={9} xs={9}>
                              <FormControl>
                                  <RadioGroup
                                    aria-labelledby="Hotel filter"
                                    {...register("hotel")}
                                    value={hotel}
                                    onChange={handleHotelChange}
                                  >
                                      <FormControlLabel
                                        value="all"
                                        control={<Radio />}
                                        label={intl.formatMessage({
                                            id: "listing.filter-all",
                                        })}
                                      />
                                      {uniqueHotels.map((hotel, index) => {
                                          return (
                                            <div className="notranslate">
                                                <FormControlLabel
                                                  key={index}
                                                  value={convertHotelNameToCode(hotel.name)}
                                                  control={<Radio />}
                                                  label={hotel.name}
                                                />
                                            </div>
                                          )
                                      })}
                                  </RadioGroup>
                              </FormControl>
                          </Col>
                          <Col md={3} xs={3}>
                              <InputLabel htmlFor="query">
                                  {intl.formatMessage({ id: "listing.filter-search" })}
                              </InputLabel>
                              <Input
                                id="query"
                                placeholder={intl.formatMessage({
                                    id: "listing.filter-search",
                                })}
                                {...register("query")}
                              />
                              <button
                                ref={inputRef}
                                className="btnSubmitSearch"
                                type="submit"
                              >
                                  <i className="icon-search"></i>
                              </button>
                          </Col>
                      </Row>
                  </form>
              </Container>
            )}
            <Container fluid>
                <Row className="rowListing">{listItems}</Row>
            </Container>
        </section>
    )
}

export default BlockListingOffers
