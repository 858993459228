import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Title from "../../common/title"
import parse from "html-react-parser"
import { useIntl } from "gatsby-plugin-react-intl"
import StyledButton from "../../common/styledButton"
import { generateUrlByLocale } from "../../../services/intl.service"
import {
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
} from "@mui/material"
import {useForm} from "react-hook-form"
import Media from "../../common/media"
import {searchEvents} from "../../../services/listingevent.service"
import queryString from "query-string"
import {trimSentence} from "../../functions"
import "../listing.scss"
import {Link} from "gatsby"
import {searchAwards} from "../../../services/listing.service"

const BlockListingAwards = ({awards, categories, language}) => {
    const intl = useIntl()
    const isSSR = typeof window === "undefined"
    const [category, setCategory] = useState(null)
    const awardsPerRow = 8
    
  // Get years from press
  const years = awards.map(press => {
    return press.year
  })

  // @TODO remove duplicate years
  const uniqueYears = [...new Set(years)].sort().reverse()

  const [year, setYear] = useState("")

  // Handle year dropdown change
  const handleYearChange = e => {
    setYear(e.target.value)
    

  }

  // Handle category radio change
  const handleCategoryChange = event => {
    setCategory(event.target.value)
    setNext(awardsPerRow)
  }

  const { register, handleSubmit } = useForm()
  useEffect(() => {
    setCategory("all")
    setYear("")
    setNext(awardsPerRow)
  }, [])

  // Filtering
  const results = searchAwards(awards, {
    category,
    year
  })
  

  const [next, setNext] = useState(awardsPerRow)
  const handleMoreAward = () => {
    setTimeout(() => {
      document.body.classList.add("scroll-down")
    }, 500)
    setNext(next + awardsPerRow)
  }

  // Award Item component with children
  const AwardItem = ({
                       children,
                       isDisabled,
                       path,
                       awardDocument,
                       externalLink,
                     }) => {
    if (isDisabled) {
      return (
          <div className="award-item" data-scroll>
            <div>{children}</div>
            {externalLink && (
                <a
                    class="blk-read-more linkExternal"
                    target="_blank"
                    href={externalLink.uri}
                >
                  <span>{externalLink.title}</span>
                </a>
            )}
            {awardDocument && (
                <a
                    class="blk-read-more linkDownload"
                    target="_blank"
                    href={awardDocument.relationships.field_media_file.url}
                >
                  <span>{intl.formatMessage({ id: "discover" })}</span>
                </a>
            )}
          </div>
      )
    } else {
      return (
          <div className="award-item" data-scroll>
            <Link to={generateUrlByLocale(path.langcode, path.alias)}>
              {children}
            </Link>
            <StyledButton
                type="link"
                path={generateUrlByLocale(path.langcode, path.alias)}
                title={intl.formatMessage({ id: "discover" })}
            />
          </div>
      )
    }
  }

  //get mobile
  const window1 = typeof window !== "undefined" ? window : ""

  const [width, setWidth] = useState(window1.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window1.innerWidth)
  }
  useEffect(() => {
    window1.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window1.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  let isMobile = width <= 640

  return (
      <>
        <section className="section-listing" data-scroll-section>
          <form className="filterForm filterStyle">
            <Container>
              <Row>
                <Col md={3} xs={12}  className="resorts">
                  <select
                    
                    value={category}
                    onChange={handleCategoryChange}
                  >
                    <option value="">
                      {intl.formatMessage({
                        id:"form_inputs.resort",
                      })}
                    </option>
                    {categories
                      .filter(
                        category =>
                          category.relationships &&
                          category.relationships.node__award
                      )
                      .map((category, index) => {
                        return (
                          <option
                            key={index}
                            value={category.id}
                          >
                            {category.name}
                          </option>
                        )
                      })}
                  </select>
                </Col>
                <Col md={3} xs={12}>
                  <select onChange={handleYearChange} value={year}>
                    <option value="">{language == "EN" ? "Year" : "Année"}</option>
                    {uniqueYears.map((year, index) => {
                      return (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      )
                    })}
                  </select>
                </Col>
              </Row>
            </Container>
          </form>
          <Container>
            <Row className="listing-generic award">
              {results.length > 0 ? results?.slice(0, next)?.map((award, index) => {
                return (
                    <AwardItem
                        path={award.path}
                        isDisabled={award.disable_page}
                        key={index}
                        externalLink={award.award_link}
                        awardDocument={award.relationships.award_documents}
                    >
                    <span className="caption">
                      <span>
                        {award.relationships.award_categories[0].name}
                      </span>
                    </span>
                      <Media media={award.relationships.award_media} />
                      <h2>{award.title}</h2>
                    </AwardItem>
                )
              }) : (
                  <div className="no-results">
                    <h2>{intl.formatMessage({ id: "page.search.no_results" })}</h2>
                  </div>
              )}
            </Row>
          </Container>
        </section>
        {next <= results?.length && (
            <section className="section-more blk-spacing" data-scroll-section>
              <Container>
                <Row>
                  <div className="grid-load-more">
                    <button className="btn btnLight" onClick={handleMoreAward}>
                      <span>{intl.formatMessage({ id: "show_more" })}</span>
                    </button>
                  </div>
                </Row>
              </Container>
            </section>
        )}
      </>
  )
}

export default BlockListingAwards
