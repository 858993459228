import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { navigate, useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import { graphql } from "gatsby"
import Breadcrumbs from "../components/common/breadcrumbs/breadcrumbs"
import { Pagination, Stack } from "@mui/material"
import { getLanguageSwitcherUrlByLocale } from "../services/intl.service"
import BlockBooking from "../components/common/blockBooking/blockBooking"
import MainBannerImage from "../components/common/mainBanner/mainBannerImage"
import { getCarouselImageOG, getHotelBookingCode } from "../components/functions"
import PageIntro from "../components/common/pageIntro/pageIntro"
import BlockListingPressReleases from "../components/listing/blockListingPressReleases/blockListingPressReleases"
import BlockListingAwards from "../components/listing/blockListingAwards/blockListingAwards"
import BlockListingOffers from "../components/listing/blockListingOffers/blockListingOffers";
import BlockListingRooms from "../components/listing/blockListingRooms/blockListingRooms"
import BlockListingEvents from "../components/common/blockListingEvents/blockListingEvents"
import BlockListingExperiences from "../components/listing/blockListingExperiences/blockListingExperiences";


export default function ListingGeneric({
  data: {
    nodeListing,
    allNodePressRelease,
    allNodeAward,
    allTaxonomyTermAwardCategories,
    allTaxonomyTermPressReleaseCategories,
    allTaxonomyTermPressReleaseTags,
    allNodeRoom,
    allNodeOffer,
    allNodeEvent,
    allNodeExperienceArticle,
    allTaxonomyTermGenre
  },
  pageContext,
}) {
  const isSSR = typeof window === "undefined"

  const intl = useIntl()

  const {
    field_seo,
    listing_type,
    relationships: {
      listing_carousel: {
        relationships: { carousel_highlight_item: carouselMea },
      },
      listing_introduction: pageIntro,
    },
    title,
  } = nodeListing

  const carouselFiltered = carouselMea.filter(
    carousel => carousel.relationships.media !== null
  )

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image: process.env.GATSBY_URL + getCarouselImageOG(carouselFiltered),
  })

  const pageTranslatedUrl = getLanguageSwitcherUrlByLocale(
    intl.locale,
    pageContext.translatedUrls
  )
  const [page, setPage] = React.useState(1)

  const handleChange = (event, value) => {
    setPage(value)
    if (value === 1) {
      navigate(pageTranslatedUrl)
    } else {
      navigate(pageTranslatedUrl + "/page/" + value)
    }
  }
  const Listing = ({ listing_type }) => {
    switch (listing_type) {
      case "press_release":
        return (
          <>
            <BlockListingPressReleases
              pressReleases={allNodePressRelease.nodes}
              categories={allTaxonomyTermPressReleaseCategories}
              tags={allTaxonomyTermPressReleaseTags}
            />
            <Stack
              spacing={2}
              className="listing-event-pagination"
              data-scroll-section
            >
              {pageContext.numPages > 1 &&
                <Pagination
                  count={pageContext.numPages}
                  page={pageContext.currentPage}
                  onChange={handleChange}
                />
              }
            </Stack>
          </>
        )
      case "award":
        return (
          <BlockListingAwards
            awards={allNodeAward.nodes}
            categories={allTaxonomyTermAwardCategories.nodes}
            language={pageContext.languageWP}
          />
        )
      case "room":
        return (
          <BlockListingRooms
            rooms={allNodeRoom.nodes}
            hotelTaxonomyId={pageContext.hotelTaxonomyDrupalId}
          />
        )
      case "offer":
        return (
          <BlockListingOffers
            offers={allNodeOffer.nodes}
            hotelTaxonomyId={pageContext.hotelTaxonomyDrupalId}
          />
        )
      case "event":
        return (
          <>
            <BlockListingEvents events={allNodeEvent} />
            <Stack
              spacing={2}
              className="listing-event-pagination"
              data-scroll-section
            >
              <Pagination
                count={pageContext.numPages}
                page={pageContext.currentPage}
                onChange={handleChange}
              />
            </Stack>
          </>
        )
      case "collectable_experiences":
        return (
          <BlockListingExperiences
            experiences={allNodeExperienceArticle.nodes}
            hotelTaxonomyId={pageContext.hotelTaxonomyDrupalId}
            genres={allTaxonomyTermGenre.nodes}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <Layout pageContext={pageContext}>
      <Seo data={seoData} pageContext={pageContext} />
      {carouselFiltered.length > 0 && (
        <MainBannerImage items={carouselFiltered} />
      )}
      <Breadcrumbs
        pageTitle={intl.formatMessage({ id: "page.eventListing.seo.title" })}
      />
      {!isSSR && (
        <React.Suspense fallback="loading.... ">
          <PageIntro slice={pageIntro} />
          <Listing listing_type={listing_type} />
        </React.Suspense>
      )}
      <BlockBooking hotel={getHotelBookingCode(pageContext.hotelTaxonomyDrupalId)} />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!, $skip: Int!, $limit: Int!, $language: String!) {
    allNodePressRelease(
      sort: { order: DESC, fields: press_release_date }
      limit: $limit
      skip: $skip
      filter: { langcode: { eq: $language }, title: { ne: "Dummy" } }
    ) {
      nodes {
        title
        press_release_date(formatString: "DD MMMM YYYY", locale: $language)
        year: press_release_date(formatString: "YYYY")
        path {
          alias
          langcode
        }
        press_release_description {
          processed
        }
        relationships {
          press_release_categories {
            id
            name
          }
          press_release_documents {
            relationships {
              field_media_file {
                url
              }
            }
          }
          press_release_media {
            field_image {
              alt
            }
            relationships {
              ...getImage
            }
          }
          press_release_tags {
            id
            name
          }
        }
      }
    }
    allNodeAward(
      filter: { langcode: { eq: $language }, title: { ne: "Dummy" } }
      sort: {fields: created, order: DESC}
    ) {
      nodes {
        title
        award_date(formatString: "DD MMMM YYYY")
        year:award_date(formatString: "YYYY")
        path {
          alias
          translated_urls
          langcode
        }
        award_link {
          uri
          title
        }
        award_description {
          processed
        }
        disable_page
        relationships {
          award_hotel {
            id
          }
          award_documents {
            relationships {
              field_media_file {
                url
              }
            }
          }
          award_categories {
            name
            id
          }
          award_media {
            field_image {
              alt
            }
            relationships {
              ...getImage
            }
          }
        }
      }
    }
    nodeListing(id: { eq: $id }) {
      ...listingFieldsGeneric
    }
    allTaxonomyTermAwardCategories(
      filter: { name: { ne: "Dummy" }, langcode: { eq: $language } }
      sort: { fields: weight }
    ) {
      nodes {
        name
        id
        relationships {
          node__award {
            id
            title
          }
        }
      }
    }
    allTaxonomyTermPressReleaseCategories(
      filter: { name: { ne: "Dummy" }, langcode: { eq: $language } }
      sort: { fields: weight }
    ) {
      nodes {
        id
        name
        relationships {
          node__press_release {
            id
            title
          }
        }
      }
    }
    allTaxonomyTermPressReleaseTags(
      filter: { name: { ne: "Dummy" }, langcode: { eq: $language } }
      sort: { fields: weight }
    ) {
      nodes {
        name
        id
        relationships {
          node__press_release {
            id
            title
          }
        }
      }
    }
    allNodeRoom(
      filter: { langcode: { eq: $language }, title: { ne: "Dummy" } }
      sort: {fields: field_weight}
    ) {
      nodes {
        path {
          alias
          langcode
        }
        field_weight
        relationships {
          room_carousel {
            ...getCarouselItems
          }
          room_hotel {
            drupal_id
            name
          }
        }
        room_adults
        room_children
        room_description {
          processed
        }
        room_price
        room_price_suffix
        link_de {
          title
          uri
          url
        }
        room_reservation_link {
          title
          uri
          url
        }
        room_surface_area
        room_view
        title
      }
    }
    allNodeOffer(
      filter: { title: { ne: "Dummy" }, langcode: { eq: $language } }
    ) {
      nodes {
        title
        path {
          alias
          langcode
        }
        offer_featured_description {
          processed
        }
        relationships {
          offer_featured_media_landscape {
            ...getFeaturedMedia
          }
          offer_hotel {
            id
            name
            drupal_id
          }
          offer_carousel {
            ...getCarouselItems
          }
          offer_introduction {
            link_de {
              url
              title
            }
            introduction_link {
              title
              url
            }
          }
        }
      }
    }
    allNodeEvent(
      sort: { order: DESC, fields: event_date }
      limit: $limit
      skip: $skip
      filter: { langcode: { eq: $language }, title: { ne: "Dummy" } }
    ) {
      nodes {
        title
        event_date(formatString: "DD/MM/YYYY")
        relationships {
          event_featured_media_landscape {
            ...getFeaturedMedia
          }
          event_introduction {
            introduction_description {
              processed
            }
            introduction_link {
              title
              url
            }
          }
          event_hotel {
            id
            name
          }
        }
        path {
          alias
          langcode
        }
      }
    }

    allNodeExperienceArticle(filter: { langcode: { eq: $language }, title: {ne: "Dummy"}, is_collectable_experience: {eq: true}}) {
      nodes {
        title
        relationships {
           genre {
            id
            name
          }
           experience_article_featured_land {
            ...getFeaturedMedia
          }
          experience_article_introduction {
            introduction_description {
              processed
            }
            introduction_link {
              title
              url
            }
          }
          experience_article_hotel {
            name
            id
          }
          experience {
            name
            id
          }
        }
        path {
          alias
          langcode
        }
      }
    }
    allTaxonomyTermGenre (filter: { langcode: { eq: $language }}, sort: {fields: name, order: ASC}) {
      nodes {
        name
        id
      }
    }
  }
`
