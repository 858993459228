import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Title from "../title"
import parse from "html-react-parser"
import { useIntl } from "gatsby-plugin-react-intl"
import StyledButton from "../styledButton"
import { generateUrlByLocale } from "../../../services/intl.service"
import { Input, InputLabel } from "@mui/material"
import { useForm } from "react-hook-form"
import Media from "../media"
import { searchEvents } from "../../../services/listingevent.service"
import queryString from "query-string"
import { trimSentence } from "../../functions"

const BlockListingEvents = ({ events }) => {
  const intl = useIntl()

  const inputRef = React.useRef(null)
  const isSSR = typeof window === "undefined"
  const [results, setResults] = useState([])

  const { register, handleSubmit } = useForm()
  const onSubmit = function (data) {
    const payload = {
      query: data.query,
    }

    setResults(searchEvents(events.nodes, payload))
  }

  let parsed

  if (!isSSR) {
    parsed = queryString.parse(window.location.search)
  }

  useEffect(() => {
    setResults(events.nodes)
  }, [])

  return (
    <section className="section-listing" data-scroll-section>
      <Container className="filterList">
        <form className="filterForm" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={9} xs={9}></Col>
            <Col md={3} xs={3}>
              <InputLabel htmlFor="query">
                {intl.formatMessage({ id: "listing.filter-search" })}
              </InputLabel>
              <Input
                id="query"
                placeholder={intl.formatMessage({
                  id: "listing.filter-search",
                })}
                {...register("query")}
              />
              <button
                ref={inputRef}
                className="btnSubmitSearch"
                type="submit"
              >
                <i className="icon-search"></i>
              </button>
            </Col>
          </Row>
        </form>
      </Container>
      <Container>
        <Row className="rowListing">
          {results.length > 0 &&
            results.map((event, index) => {
              return (
                  <Col md={12} xs={12} key={index}>
                  <div className="listingDetails">
                    <div>
                      {event.relationships.event_featured_media_landscape && (
                        <Media
                          media={
                            event.relationships.event_featured_media_landscape
                              .relationships.featured_media_media
                          }
                        />
                      )}
                    </div>
                    <div className="listingInfo">
                      <Title heading={2} size={2} text={event.title} />
                      {event.relationships.event_introduction && (
                        <div className="description animTxt">
                          <div>
                            {parse(
                              trimSentence(
                                event.relationships.event_introduction
                                  ?.introduction_description?.processed,
                                200
                              )
                            )}
                          </div>
                        </div>
                      )}
                      <div className="blkButtons">
                        <div className="blkLinks">
                          <div>
                            <StyledButton
                              title={intl.formatMessage({ id: "learn_more" })}
                              type="link"
                              path={generateUrlByLocale(
                                event.path.langcode,
                                event.path.alias
                              )}
                            />
                          </div>
                          <div>
                            {event.event_introduction && (
                              <StyledButton
                                title={
                                  event.event_introduction.introduction_link
                                    .title
                                }
                                type="link"
                                path={
                                  event.event_introduction.introduction_link
                                    .url
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              )
            })}
        </Row>
      </Container>
    </section>
  )
}

export default BlockListingEvents
