import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Slider from "react-slick"

const ImageSlider = ({ images }) => {
  const settings = {
    autoplay: false,
    dots: true,
    infinite: false,
    fade: false,
    speed: 1000,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: true,
  }

  return (
    <div className="blk-image-slider animImgRight">
      <Slider {...settings}>
        {images.map((image, index) => {
          if (image.relationships.media !== null) {
            return (
              <GatsbyImage
                key={index}
                image={getImage(
                  image.relationships.media?.relationships.field_image.gatsbyImage
                )}
              />
            )
          }
        })}
      </Slider>
    </div>
  )
}

export default ImageSlider
