import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Title from "../../common/title"
import parse from "html-react-parser"
import { useIntl } from "gatsby-plugin-react-intl"
import StyledButton from "../../common/styledButton"
import { generateUrlByLocale } from "../../../services/intl.service"
import { Input, InputLabel } from "@mui/material"
import { useForm } from "react-hook-form"
import Media from "../../common/media"
import queryString from "query-string"
import { trimSentence } from "../../functions"
import "../listing.scss"
import { graphql, Link } from "gatsby"
import { searchPress } from "../../../services/listing.service"

const BlockListingPressReleases = ({ pressReleases, categories, tags }) => {
  const intl = useIntl()

  const inputRef = React.useRef(null)
  const isSSR = typeof window === "undefined"
  const { register, handleSubmit } = useForm()

  // Get years from press
  const years = pressReleases.map(press => {
    return press.year
  })

  // @TODO remove duplicate years
  const uniqueYears = [...new Set(years)]

  const [results, setResults] = useState("")
  const [selectedCategory, setSelectedCategory] = useState("")
  const [selectedTag, setSelectedTag] = useState("")
  const [selectedYear, setSelectedYear] = useState("")
  // const [selectedMedia, setSelectedMedia] = useState("")

  let parsed, tagParam

  if (!isSSR) {
    parsed = queryString.parse(window.location.search)
    // tagParam = parsed?.hotel ? convertHotelNameToCode(parsed.hotel) : ""
  }

  const onSubmit = function (data) {
    const payload = {
      selectedCategory,
      selectedTag,
      selectedYear,
      query: data.query,
    }
    setResults(searchPress(pressReleases, payload))
  }

  useEffect(() => {
    setResults(pressReleases)
  }, [])

  // Handle category dropdown change
  const handleCategoryChange = e => {
    setSelectedCategory(e.target.value)
    //Set click on submit after radio change
    setTimeout(() => {
      inputRef.current.click()
    }, 500)
  }

  // Handle tag dropdown change
  const handleTagChange = e => {
    setSelectedTag(e.target.value)
    //Set click on submit after radio change
    setTimeout(() => {
      inputRef.current.click()
    }, 500)
  }

  // Handle year dropdown change
  const handleYearChange = e => {
    setSelectedYear(e.target.value)
    //Set click on submit after radio change
    setTimeout(() => {
      inputRef.current.click()
    }, 500)
  }

  // Filtering
  // const results = searchPress(pressReleases, {
  //   selectedCategory,
  //   selectedTag,
  //   selectedYear
  // })

  return (
    <>
      <section className="filterBar" data-scroll-section>
        <form className="filterForm filterStyle" onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <Row>
              {categories.nodes && categories.nodes.length > 0 && (
                <Col md={3} xs={12}>
                  <select
                    id="selectHotel"
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                  >
                    <option value="">
                      {intl.formatMessage({
                        id: "form_inputs.all_categories",
                      })}
                    </option>
                    {categories.nodes
                      .filter(
                        category =>
                          category.relationships &&
                          category.relationships.node__press_release
                      )
                      .map((category, index) => {
                        return (
                          <option key={index} value={category.id}>
                            {category.name}
                          </option>
                        )
                      })}
                  </select>
                </Col>
              )}
              {tags.nodes && tags.nodes.length > 0 && (
                <Col md={3} xs={12}>
                  <select onChange={handleTagChange} value={selectedTag}>
                    <option value="">
                      {intl.formatMessage({
                        id: "form_inputs.all_tags",
                      })}
                    </option>
                    {tags.nodes
                      .filter(
                        tag =>
                          tag.relationships &&
                          tag.relationships.node__press_release
                      )
                      .map((tag, index) => {
                        return (
                          <option key={index} value={tag.id} className="notranslate">
                            {tag.name}
                          </option>
                        )
                      })}
                  </select>
                </Col>
              )}
              <Col md={3} xs={12}>
                <select onChange={handleYearChange} value={selectedYear}>
                  <option value="">Date</option>
                  {uniqueYears.map((year, index) => {
                    return (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    )
                  })}
                </select>
              </Col>
              <Col md={3} xs={12}>
                <InputLabel htmlFor="query">
                  {intl.formatMessage({ id: "listing.filter-search" })}
                </InputLabel>
                <Input
                  id="query"
                  placeholder={intl.formatMessage({
                    id: "listing.filter-search",
                  })}
                  {...register("query")}
                />
                <button
                  ref={inputRef}
                  className="btnSubmitSearch"
                  type="submit"
                >
                  <i className="icon-search"></i>
                </button>
              </Col>
            </Row>
          </Container>
        </form>
      </section>
      <section className="section-listing" data-scroll-section>
        <Container>
          <Row className="listing-generic press">
            {results.length > 0 ?
              results.map((press, index) => {
                return (
                  <div key={index} data-scroll>
                    <Link
                      to={generateUrlByLocale(
                        press.path.langcode,
                        press.path.alias
                      )}
                    >
                      {press.relationships.press_release_categories.length >
                        0 && (
                        <span className="caption">
                          <span>
                            {
                              press.relationships.press_release_categories[0]
                                .name
                            }
                          </span>
                        </span>
                      )}
                      <Media media={press.relationships.press_release_media} />
                      <div className="tags-listing">
                        {press.relationships.press_release_tags.length > 0 &&
                          press.relationships.press_release_tags.map(
                            (tag, index) => {
                              if (
                                index !==
                                press.relationships.press_release_tags.length -
                                  1
                              ) {
                                return <span key={index}>{tag.name} / </span>
                              } else {
                                return <span key={index}>{tag.name}</span>
                              }
                            }
                          )}
                      </div>
                      <h2>{press.title}</h2>
                      <div className="dateInfo">{press.press_release_date}</div>
                      {press.press_release_description && (
                        <div className="article-excerpt">
                          {trimSentence(
                            press.press_release_description.processed,
                            100
                          )}
                        </div>
                      )}
                    </Link>
                    <StyledButton
                      type="link"
                      path={generateUrlByLocale(
                        press.path.langcode,
                        press.path.alias
                      )}
                      title={intl.formatMessage({ id: "discover" })}
                    />
                  </div>
                )
              }) :
              (
                <div className="no-results">
                  <h2>{intl.formatMessage({ id: "page.search.no_results" })}</h2>
                </div>
              )}
          </Row>
        </Container>
      </section>
    </>
  )
}

export const query = graphql`
  fragment getPressReleases on node__press_release {
    title
    press_release_date(formatString: "DD MMMM YYYY", locale: $language)
    year: press_release_date(formatString: "YYYY")
    path {
      alias
      langcode
    }
    press_release_description {
      processed
    }
    relationships {
      press_release_categories {
        id
        name
      }
      press_release_documents {
        relationships {
          field_media_file {
            url
          }
        }
      }
      press_release_media {
        field_image {
          alt
        }
        relationships {
          ...getImage
        }
      }
      press_release_tags {
        id
        name
      }
    }
  }
`

export default BlockListingPressReleases
